.board {
  margin: 0 auto;
  width: 600px;
  display: flex;
  flex-wrap: wrap;
}

.board-card {
  flex: 1 1 150px;
  margin-bottom: 20px;
  transition: .2s;
}

@media only screen and (max-width: 600px) {
  .board {
    width: 100%;
  }
  .board-card {
    flex: 1 1 100px;
  }
}
