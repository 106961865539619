.play-start {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.play-start__intro {
  display: flex;
  margin-bottom: 40px;
}

.play-start__intro img {
  height: 80px;
  margin-left: 24px;
}

.github-logo-link {
  position: absolute;
  width: 40px;
  height: 40px;
  bottom: 40px;
  right: 40px;
}

.github-logo {
  width: 100%;
}
