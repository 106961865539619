.play-card {
  height: 200px;
  width: 125px;
  border-radius: 5px;
  border: 1px solid lightgray;
  cursor: pointer;
  transition: .3s;
}

.play-card__active {
  border: 3px solid gray;
}

.playcard__image {
  transform: rotate(90deg);
  width: 100%;
  margin-top: 60px;
}

@media only screen and (max-width: 600px) {
  .play-card {
    height: 150px;
    width: 100px;
  }

  .playcard__image {
    margin-top: 42px;
  }
}
